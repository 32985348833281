function AppFooterCopyright() {
	return (
		<div 
			style={{ fontSize: '15px'}} 
			className="font-general-regular flex justify-center items-center text-center">
			
			<div className="text-ternary-dark dark:text-ternary-light">
				&copy; {'2023 Pritesh Thakur | '}
					Template by:
				<a
					href="https://stoman.me"
					target="__blank"
					className="text-secondary-dark dark:text-secondary-light hover:underline hover:text-indigo-600 dark:hover:text-indigo-300 ml-1 duration-500"
				>
					Stoman
				</a>
			</div>
		</div>
	);
}

export default AppFooterCopyright;
