import { AnimatePresence } from 'framer-motion';
import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import AppFooter from './components/shared/AppFooter';
import AppHeader from './components/shared/AppHeader';
import './css/App.css';
import UseScrollToTop from './hooks/useScrollToTop';

const About = lazy(() => import('./pages/AboutMe'));
const Contact = lazy(() => import('./pages/Contact.jsx'));
const Home = lazy(() => import('./pages/Home'));
const Projects = lazy(() => import('./pages/Projects'));
const Awards = lazy(() => import('./pages/AwardsAndHonors.jsx') )
const Research = lazy(() => import('./pages/Research.jsx'))

const Pashupati = lazy(() => import('./pages/Pashupati.jsx'))
const Ardra = lazy(() => import('./pages/Ardra.jsx'))
const Cansat = lazy(() => import('./pages/Cansat'))
const Lipi = lazy(() => import('./pages/Lipi'))
const Car = lazy(() => import('./pages/Car'))
const Asteroid = lazy(() => import('./pages/Asteroid'))

function App() {
	return (
		<AnimatePresence>
			<div className=" bg-secondary-light dark:bg-primary-dark transition duration-300">
				<Router>
					<ScrollToTop />
					<AppHeader />
					<Suspense fallback={""}>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="projects" element={<Projects />} />

							<Route path="about" element={<About />} />
							<Route path="contact" element={<Contact />} />
							<Route path="awards" element={<Awards />} />
							<Route path="research" element={<Research />} />

							<Route path="pashupati" element={<Pashupati />} />
							<Route path="ardra" element={<Ardra />} />
							<Route path="satellite" element={<Cansat />} />
							<Route path="lipi" element={<Lipi />} />
							<Route path="car" element={<Car />} />
							<Route path="asteroid" element={<Asteroid />} />

						</Routes>
					</Suspense>
					<AppFooter />
				</Router>
				<UseScrollToTop />
			</div>
		</AnimatePresence>
	);
}

export default App;
